<template>
  <div class="collection-container flex-column-between">
    <div class="col-nav">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="项目关注" name="0"></el-tab-pane>
        <el-tab-pane label="通知关注" name="1"></el-tab-pane>
        <el-tab-pane label="政策关注" name="2"></el-tab-pane>
        <el-tab-pane label="公示关注" name="3"></el-tab-pane>
        <el-tab-pane label="部门关注" name="7"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="collection-container flex-column-between">

      <!--列表-->
      <el-table :data="tableData" style="width: 100%" stripe border max-height="500"
                size="medium">
        <el-table-column label="标题" width="800">
          <template slot-scope="scope">
            <span class="max-one-line">{{ scope.row.collectionName }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button @click.native.prevent="gotoCollection(scope.$index)"
                       type="primary" plain>查看
            </el-button>

            <el-button @click.native.prevent="deleteCollection(scope.$index)"
                       type="danger" plain>删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--分页-->
      <!--      <el-pagination style="margin-top:16px;" background hide-on-single-page :total="total"
                           :page-size="pageSize"
                           @current-change="changePageNum"></el-pagination> -->
      <el-pagination
        hide-on-single-page
        style="margin-top:16px;"
        layout="total, prev, pager, next" background
        :total="total"
        :page-size="pageSize"
        @current-change="changePageNum">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    components: {},
    data() {
      return {
        keyWord: "",//搜索关键字
        tableData: [],
        total: 0,
        currentPageNum: 1,
        pageSize: 6,
        activeName: "0",
        collectType: 0,
        src: require('@/assets/404_images/no_data.png')
      };
    },
    computed: {
      ...mapGetters({
        userInfo: "getUserInfo",
      }),
    },
    mounted() {
      this.getDataList()
    },
    methods: {
      gotoCollection(index) {

        let item = this.tableData[index]
        let collectionId = item.collectionId
        let collectType = parseInt(item.type)
        if (collectType === 7) {
          // this.$router.push({
          //   path: "/deptInfo/" + collectionId
          // });
          let {href} = this.$router.resolve({
            path: "/deptInfo/" + collectionId
          });
          window.open(href, "_blank");
        } else {
          let link = window.location.href
          let end = link.indexOf("#")
          let suffix = link.substr(0, end)

          //collectType 对应 页面的type
          //1 通知 -> 2通知公告
          // 2 政策 -> 1项目政策
          // 3 信息公示 -> 3公开公示
          //事项的 collectType 对应 事项的itemType
          // 4 审批类事项 -> 1
          // 5 扶持类事项 -> 2
          // 6 诉求类事项 -> 3
          let map = new Map()
          map.set(1, 2)
          map.set(2, 1)
          map.set(3, 3)
          map.set(4, 1)
          map.set(5, 2)
          map.set(6, 3)
          let type = map.get(collectType)
          console.log("type:" + type);
          let url = ""
          if (collectType == 1 || collectType == 2 || collectType == 3) {
            url = suffix + "#/notice/detail/" + collectionId + "?type=" + type
          } else {
            //跳转到具体的项目申报页面
            if (collectType == 4 || collectType == 5) {
              url = suffix + "#/guideShenpi" + "?itemType=" + type + "&collectionGuideId=" + item.collectionId;
            } else {
              url = suffix + "#/guideSuqiu?collectionGuideId=" + item.collectionId;
            }
          }
          window.location.href = url;
        }
      },
      //删除关注
      deleteCollection(index) {

        this.$confirm('确认删除此关注??', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let item = this.tableData[index]
          let itemId = item.id

          this.$httpApi.post("/dev-api/collection/delete/" + itemId, {})
            .then(res => {
              if (res.code == 200) {
                this.collectItemId = ""
                //刷新数据
                this.getDataList()
              }
            })
            .catch((err) => {

            });
        })
      },
      handleClick(tab, event) {
        this.collectType = tab.name
        this.getDataList()
      },
      changePageNum(current) {
        //current是当前页数
        this.currentPageNum = current
        this.getDataList()
      },
      //请求数据
      getDataList() {
        let pageNum = this.currentPageNum
        let keyword = this.keyWord
        let pageSize = this.pageSize

        if (this.$store.getters.getUserInfo) {
          let identify = this.$store.getters.getUserInfo.shxydm
          let collectType = this.collectType
          let param
          if (collectType == 0) {
            param = {
              pageNum: pageNum,
              pageSize: pageSize,
              fileName: keyword,
              identify: identify,
              type: "4,5,6"
            }
          } else {
            param = {
              pageNum: pageNum,
              pageSize: pageSize,
              fileName: keyword,
              identify: identify,
              type: collectType
            }
          }

          let url = "/dev-api/collection/list"
          this.$httpApi.get(url, param)
            .then(res => {
              console.log("收藏列表" + JSON.stringify(res));
              if (res.code == 200) {
                this.total = res.total;
                this.tableData = res.rows
              }
            });

        }
      },
    },
  };
</script>
<style lang="scss">
  .collection-container {
    // padding: 20px 30px;
    width: 100%;
    height: 100%;

    .col-nav {
      width: 100%;

      .el-tabs {
        .el-tabs__header {
          // background: #333;
          .el-tabs__nav {
            height: 50px;
          }

          .el-tabs__item {
            height: 26px;
            // color:#2d81ff;
            font-family: Microsoft YaHei;
            font-size: 16px;
            font-weight: bold;
            line-height: 45px;
          }

          .el-tabs__active-bar {
            width: 81px;
            height: 3px;
            background-color: #2d81ff;
          }
        }
      }

      .img {
        width: 80%;
        // background: red;
      }
    }

  }
</style>
